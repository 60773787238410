import React from 'react'
import ContactForm from './ContactForm'
function Contact() {
  return (
    <div>
   
      <ContactForm/>
    </div>
  )
}

export default Contact
