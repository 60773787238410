import React from 'react';
import './MissionVision.css';

const MissionVisionIcon = () => {
    return (
        <div className="icon-circle">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M17.4548 0L16.2427 1.21211L16.8487 1.81832L16.2427 2.42432L18.6669 4.84864L19.2731 4.24264L19.8792 4.84864L21.0914 3.63659L17.4548 0Z" fill="#2AA7FF"/>
                <path d="M18.742 11.7311C18.5769 12.0906 18.3573 12.4319 18.0611 12.7281C17.4483 13.3408 16.6604 13.6591 15.8587 13.711C16.5851 15.6885 15.9946 17.7211 14.6755 19.0401C14.4692 19.2464 14.2468 19.4277 14.0165 19.5934L4.72701 10.3037L4.12091 10.9098L10.4896 17.2785C10.0606 17.3429 9.64723 17.5336 9.31702 17.8638L8.98359 18.1971L7.75734 16.9709L8.3635 16.3646L4.72701 12.7282L2.90869 14.5464L6.54523 18.1829L7.15134 17.5769L8.37759 18.8031L6.60919 20.5716H5.48012C4.53335 20.5716 3.76589 21.3392 3.76589 22.2859L3.76594 24.0002H17.4802L17.4802 22.2859C17.4802 22.2598 17.4736 22.2352 17.4724 22.2093L17.4785 22.2108C17.4492 21.5434 17.6422 20.8993 18.0285 20.3542C19.7397 17.9404 20.1709 14.6838 18.742 11.7311ZM10.7879 20.2218C10.3146 20.2218 9.93079 19.838 9.93079 19.3646C9.93079 18.8913 10.3146 18.5075 10.7879 18.5075C11.2613 18.5075 11.6451 18.8913 11.6451 19.3646C11.6451 19.838 11.2613 20.2218 10.7879 20.2218Z" fill="#2AA7FF"/>
                <path d="M18.667 6.06056L15.0305 2.42397C14.6957 2.08915 14.1531 2.08915 13.8183 2.42397C12.4325 3.8097 8.66423 7.57813 7.37498 8.86743C7.23178 9.01057 7.15137 9.20477 7.15137 9.40732V11.1991C7.15131 11.4017 7.23183 11.5958 7.37498 11.739L9.35198 13.7161C9.49517 13.8592 9.68948 13.9398 9.89192 13.9398H11.6837C11.8862 13.9398 12.0805 13.8594 12.2236 13.7161C12.8141 13.1258 12.2811 13.6588 13.8182 12.1215C14.8224 13.1257 16.4506 13.1257 17.4548 12.1215C18.459 11.1172 18.459 9.48913 17.4548 8.48488L18.6635 7.2762C18.9982 6.94132 19.0018 6.39511 18.667 6.06056ZM9.57564 10.3032L8.96953 9.69715L14.4243 4.24229L15.0304 4.84834L9.57564 10.3032ZM15.6365 11.1603C15.1631 11.1603 14.7794 10.7765 14.7794 10.3031C14.7794 9.82979 15.1631 9.446 15.6366 9.446C16.1099 9.446 16.4937 9.82979 16.4937 10.3031C16.4937 10.7765 16.11 11.1603 15.6365 11.1603Z" fill="#2AA7FF"/>
            </svg>
        </div>
    );
};

export default MissionVisionIcon;
