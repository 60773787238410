// src/components/CustomSVGIcon.js
import React from 'react';
import './Aerodown.css'


const Aerodown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="92" height="91" viewBox="0 0 92 91" fill="none">
    <g clipPath="url(#clip0_706_13152)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.86273 51.5282C25.4838 29.4706 56.3867 33.6415 81.5028 47.7884C81.9347 48.0305 82.4833 47.8783 82.7254 47.4465C82.9676 47.0146 82.8154 46.4661 82.3835 46.2239C56.5648 31.6794 24.8066 27.5968 2.58019 50.2728C2.23321 50.6254 2.23935 51.1935 2.59375 51.5418C2.94631 51.8888 3.51442 51.8826 3.86273 51.5282Z"
        fill="#B0EFC9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.4723 46.7408C79.895 46.7425 77.9155 46.7228 77.6908 46.7271C71.6015 46.844 65.8567 47.8993 60.0672 49.8306C59.5971 49.9868 59.3429 50.4968 59.4991 50.9669C59.6552 51.437 60.1652 51.6912 60.6353 51.535C66.2487 49.6604 71.8199 48.6354 77.7267 48.5228C78.071 48.516 82.5208 48.563 83.4213 48.5049C83.7908 48.4828 83.993 48.3555 84.0378 48.3209C84.2829 48.1464 84.3694 47.9319 84.4033 47.7547C84.4401 47.5544 84.4202 47.209 84.1562 46.8481C83.8785 46.4716 83.1143 45.8355 82.9343 45.6593C80.2267 42.9939 76.9726 40.2551 74.4825 37.1147C72.1077 34.12 70.4259 30.756 70.7336 26.6984C70.7715 26.2044 70.3992 25.773 69.9053 25.7351C69.4113 25.6972 68.9798 26.0694 68.9419 26.5633C68.5988 31.1 70.4209 34.8831 73.0745 38.2298C75.5502 41.3514 78.7572 44.0895 81.4723 46.7408Z"
        fill="#B0EFC9"
      />
    </g>
    <defs>
      <clipPath id="clip0_706_13152">
        <rect width="65" height="65" fill="white" transform="translate(53 91) rotate(-143.885)" />
      </clipPath>
    </defs>
  </svg>
);

export default Aerodown;
