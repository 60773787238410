import Hero from "./Hero";
import OurServices from "./OurServices";
import Testimonials from "./Testimonials";

const Services = () => {
  return (
    <>
      <Hero />
      <OurServices />
      {/* <Testimonials /> */}
    </>
  );
};
export default Services;
