import React, { useState } from 'react';
import './ContactForm.css';
import { FaPhoneAlt, FaRegCalendarCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
 
function ContactForm() {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
 
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
 
    // Simulate form submission
    setTimeout(() => {
      setLoading(false);
      setSubmitted(true);
    }, 2000);
  };
 
  return (
    <div className="contact-container">
      <div className="left-section">
        <h1>We are here to  <span className="highlight"> Assist</span> you anytime</h1>
        {/* <p>Delivering world-class neonatology care</p> */}
       
        <div className="info-row">
        
        </div>
       
        <div className="flex-container-contact">
          <div className="flex-item flex">
            <div className="support-text">
              <p>24/7 Helpline <br />
              <div className='support-text-flex '>
               
                <Link to='tel:+8619909744' className="bold-text"><FaPhoneAlt className="icon" />8619909744</Link>
              </div>
              </p>
            </div>
          </div>
 
          <div className="line-contact "></div>
 
          <div className="flex-item">
            <p>Online Schedule <br />
              <div className='support-text-flex '>
                <FaRegCalendarCheck className="icon" />
                <Link  to="/bookappointment" className="bold-text">Book here</Link>
              </div>
            </p>
          </div>
        </div>
      </div>
 
      <div className="right-section">
        <h2>Send us a message</h2>
        {submitted ? (
          <p>Thank you for your message! We'll get back to you shortly.</p>
        ) : (
          <form onSubmit={handleSubmit}>
            <label htmlFor="name">First and Last name*</label>
            <input type="text" id="name" name="name" placeholder="Your name" required />
 
            <label htmlFor="email">Email address*</label>
            <input type="email" id="email" name="email" placeholder="Your email" required />
 
            <label htmlFor="condition">Condition*</label>
            <input type="text" id="condition" name="condition" placeholder="Clinical Depression" required />
 
            <label htmlFor="notes">Additional notes</label>
            <textarea id="notes" name="notes" placeholder="Additional notes"></textarea>
 
            <button type="submit" disabled={loading}>
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </form>
        )} 
      </div>
    </div>
  );
}
 
export default ContactForm;