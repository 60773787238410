import React from 'react';

const AerodUp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="93"
    height="93"
    viewBox="0 0 93 93"
    fill="none"
    {...props}
    style={{ marginTop: '48px' }} // Corrected style property
  >
    <g clipPath="url(#clip0_706_13160)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.94882 46.0717C28.0581 65.3785 58.2382 57.5334 81.4774 40.4778C81.8771 40.1856 82.44 40.271 82.7322 40.6707C83.0244 41.0704 82.939 41.6332 82.5393 41.9254C58.6502 59.4599 27.6104 67.32 2.82602 47.4718C2.43928 47.1633 2.37728 46.5986 2.68737 46.2103C2.9958 45.8235 3.56054 45.7616 3.94882 46.0717Z"
        fill="#B0EFC9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.5724 41.5228C80.0063 41.7101 78.0434 41.967 77.8198 41.9896C71.7604 42.6035 65.9305 42.2444 59.9513 41.021C59.4658 40.9223 59.1523 40.4465 59.251 39.9611C59.3497 39.4757 59.8255 39.1622 60.3109 39.2609C66.1086 40.4491 71.7625 40.7988 77.6402 40.2026C77.9828 40.1681 82.3949 39.588 83.2959 39.5377C83.6653 39.5154 83.8813 39.6175 83.93 39.6465C84.1942 39.7903 84.3058 39.993 84.3607 40.1648C84.4212 40.3592 84.4429 40.7046 84.2241 41.0945C83.9935 41.5015 83.311 42.2247 83.1535 42.4212C80.7849 45.3919 77.8825 48.5011 75.7869 51.9173C73.7882 55.1751 72.5217 58.7164 73.3136 62.7079C73.4104 63.1938 73.0926 63.6667 72.6067 63.7636C72.1209 63.8604 71.6479 63.5426 71.551 63.0568C70.6666 58.594 72.022 54.6196 74.2553 50.979C76.339 47.5832 79.1946 44.4804 81.5724 41.5228Z"
        fill="#B0EFC9"
      />
    </g>
    <defs>
      <clipPath id="clip0_706_13160">
        <rect
          width="65"
          height="65"
          fill="white"
          transform="matrix(-0.731354 0.681998 0.681998 0.731354 48 0.996094)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default AerodUp;
